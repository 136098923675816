import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import './all.sass'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltRight, faSearchDollar, faDollarSign} from '@fortawesome/free-solid-svg-icons'

library.add(
  faLongArrowAltRight,
  faSearchDollar,
  faDollarSign
)
const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <div>
        <Helmet
          titleTemplate="%s | Tithes.App"
        >
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />
          
          <link rel="apple-touch-icon" sizes="180x180" href="/img/icons/apple-touch-icon.png" />
	        <link rel="icon" type="image/png" href="/img/icons/favicon-48x48.png" sizes="48x48" />
	        <link rel="icon" type="image/png" href="/img/icons/favicon-72x72.png" sizes="72x72" />
	
	        {/* <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" /> */}
	        <meta name="theme-color" content="#fff" />

	        <meta property="og:type" content="website" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content={data.site.siteMetadata.url} />
          <meta property="og:image" content="/img/Tithe-Calculator-App.png" />

          <meta property="twitter:card" content="summary" />
          <meta property="twitter:creator" content="@jonhorton" />
          <meta property="twitter:site" content="@jonhorton" />
          <meta property="twitter:image" content="/img/Tithe-Calculator-App.png" />
          <meta property="twitter:title" content={data.site.siteMetadata.title} />
          <meta property="twitter:description" content={data.site.siteMetadata.description} />
        </Helmet>
        {/* <Navbar /> */}
        <div>{children}</div>
      </div>
    )}
  />
)

export default TemplateWrapper
